<template>
    <div class="prtf-page-header-subtitle_item link" style="margin-left:-3px" @click="open_client_page">
        <svg-icon icon-class="user" />
        {{client.first_name}} {{client.last_name}}
    </div>
</template>

<script>
export default {
    props: {
        client: {
            required : true,
            type     : Object,
        },
    },

    methods: {
        open_client_page(){
            this.$router.push('/client/' + this.client.id)
        }
    }
}
</script>
