<template>
    <div class="prtf-holder prtf-holder-portfolio" @scroll="handle_body_scroll">
        <div class="prtf-page prtf-page-portfolio" v-if="client && portfolio" v-loading="loading">
            <div :class="['prtf-page-header-fixed', {'prtf-page-header-fixed--hidden': !show_header} ]" >
                <div class="prtf-page-header-fixed-l">
                    <div class="prtf-page-header-button">
                        <el-select v-model="portfolio_mode" @change="change_mode" class="prtf-page-portfolio-mode">
                            <el-option label="By value date" value="value"></el-option>
                            <el-option label="By trade date" value="trade"></el-option>
                        </el-select>
                    </div>
                    <div class="prtf-page-header-button prtf-page-portfolio-date">
                        <date-picker
                            v-model="portfolio_date"
                            type="date"
                            format="DD MMM YY"
                            value-type="date"
                            placeholder="Date"
                            :clearable="false"
                            @change="change_date">
                        </date-picker>
                    </div>
                </div>
                <div class="prtf-page-header-fixed-r">
                    <div class="prtf-page-header-button">
                        <el-button type="danger" @click="recalculate">
                            Recalc
                        </el-button>
                    </div>
                    <div class="prtf-page-header-button">
                        <el-button @click="open_analytics">
                            Analytics
                        </el-button>
                    </div>
                    <div class="prtf-page-header-button">
                        <el-button @click="open_accounts">
                            Accounts
                        </el-button>
                    </div>
                    <div class="prtf-page-header-button">
                        <el-button @click="open_history">
                            History
                        </el-button>
                    </div>
                    <div class="prtf-page-header-button">
                        <el-button  @click="new_operation_select_window_open = true">
                            <svg-icon icon-class="plus" />
                            Add operations
                        </el-button>
                    </div>
                </div>
            </div>

            <div class="prtf-page-header">
                <div class="prtf-page-header-title-holder">
                    <div class="prtf-page-header-maintitle">
                        <div class="prtf-page-header-name">{{portfolio.name}}</div>
                    </div>
                    <div class="prtf-page-header-subtitle">
                        <div class="prtf-page-header-subtitle_item">
                            {{portfolio.currency}}
                        </div>
                        <div class="prtf-page-header-subtitle_item">
                            {{moment(portfolio.date).format('DD MMM YYYY')}}
                        </div>
                        <ClientHeaderLink :client="client" />
                    </div>
                </div>
            </div>

            <div class="prtf-page-body prtf-page-body-portfolio">
                <DataGrid
                    :expandable="true"
                    :showsold="true"
                    :portfolio="portfolio._id"
                    :source_list="portfolio.list"
                    :source_loading="loading"
                    v-if="'list' in portfolio"
                    :client="client"
                    :transfer_available="transfer_available"
                    :max_height="max_table_height"
                    :portfolio_mode="portfolio_mode"
                    :portfolio_date="portfolio_date" />
            </div>

            <NewOperationSelectWindow
                :open="new_operation_select_window_open"
                @closed="new_operation_select_window_open = false"
                :client="client"
                :portfolio="portfolio" />
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
import ClientHeaderLink from '@/components/PageComponents/ClientHeaderLink'
import DataGrid from './Components/DataGrid'
import NewOperationSelectWindow from './Components/NewOperationSelectWindow'

export default {
    components: {
        ClientHeaderLink,
        DataGrid,
        NewOperationSelectWindow,
    },
    props: {
        clientid: {
            required: true,
        },
        portfolioid: {
            required: true,
        },
    },

    data(){
        return {
            client                           : undefined,
            portfolio                        : undefined,
            portfolio_mode                   : 'trade',
            portfolio_date                   : new Date,
            edit_window_open                 : false,
            new_operation_select_window_open : false,
            loading                          : false,
            show_header                      : true,
        }
    },

    computed: {
        transfer_available(){
            return false
        },
        max_table_height(){
            return $(window).height() - 292
        }
    },

    methods: {
        get_list(){
            let params = {
                portfolio_id : this.portfolio._id,
                client_id    : this.client._id,
                mode         : this.portfolio_mode,
                date         : this.$moment(this.portfolio_date).format('YYYY-MM-DD')
            }
            this.loading = true;
            this.$store.dispatch('portfolio/getAnalytics', params).then((response) => {
                this.$set(this.portfolio, 'data', response.data)
                this.$set(this.portfolio, 'list', response.list)
                this.loading = false;
            })
        },

        recalculate(){
            const recalculate_loading = this.$loading({
                lock: true,
                text: 'Recalculating Portfolio',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            this.$store.dispatch('portfolioOperations/recalculate_portfolio', {client_id: this.client._id, portfolio_id: this.portfolio._id}).then(() => {
                recalculate_loading.close();
                this.$emit('refresh')
                this.get_list()
            })
        },

        change_date(){
            this.get_list();
        },
        change_mode(){
            this.get_list();
        },

        open_accounts(){
            this.$router.push({ path: '/accounts/' + this.client.id + '/' + this.portfolio._id})
        },
        open_history(){
            this.$router.push({ path: '/operations/' + this.client.id + '/' + this.portfolio._id})
        },
        open_analytics(){
            this.$router.push({ path: '/portfolio-analytics/' + this.client.id + '/' + this.portfolio._id})
        },

        get_portfolio(){
            this.$store.dispatch('portfolio/getPortfolio', this.portfolioid).then((portfolio_response) => {
                this.portfolio = portfolio_response
                this.get_list()

                this.$emit('tab-title-change', 'Portfolio ' + this.portfolio.name + ' for ' + this.client.first_name + ' ' + this.client.last_name)
            })
        },

        handle_body_scroll(e){
            const currentScrollPosition = e.srcElement.scrollTop
            if (currentScrollPosition < 0) {
                return
            }
            if (Math.abs(currentScrollPosition - this.last_scroll_position) < 10) {
                return
            }
            this.show_header = currentScrollPosition < this.last_scroll_position
            this.last_scroll_position = currentScrollPosition
        },
    },

    mounted(){
        if (this.clientid && this.portfolioid) {
            this.$store.dispatch('clients/getClient', this.clientid).then((client_response) => {
                this.client = client_response
                this.get_portfolio();
            })
        }
    }
}
</script>
