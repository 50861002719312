<template>
    <el-dialog
        :visible.sync="show_modal"
        width="450px"
        append-to-body
        modal-append-to-body
        :close-on-click-modal="false"
        :destroy-on-close="true">

        <template slot="title"><div class="tl-dialog-title">Select Operation Type</div></template>

        <div class="newop-select-block">
            <el-button type="success" plain @click="open_operation_window({'type':'buy'})">Buy</el-button>
            <el-button type="danger" plain @click="open_operation_window({'type':'sell'})">Sell</el-button>
        </div>
        <div class="newop-select-block">
            <el-button type="success" plain @click="open_operation_window({'type':'cover_short'})">Cover Short</el-button>
            <el-button type="danger" plain @click="open_operation_window({'type':'sell_short'})">Sell Short</el-button>
        </div>
        <div class="newop-select-block">
            <el-button @click="open_operation_window({'type':'deposit'})">Asset Deposit</el-button>
            <el-button  @click="open_operation_window({'type':'withdraw'})">Asset Withdraw</el-button>
        </div>
        <div class="newop-select-block">
            <el-button @click="open_operation_window({'type':'deposit', 'asset':'cash'})">Cash Deposit</el-button>
            <el-button @click="open_operation_window({'type':'withdraw', 'asset':'cash'})">Cash Withdraw</el-button>
        </div>
        <div class="newop-select-block">
            <el-button>Transfer</el-button>
            <el-button @click="open_operation_window({'asset':'cash'})">FX</el-button>
        </div>

    </el-dialog>
</template>

<script>
export default {
    props: {
        open: {
            type: Boolean,
            default: false
        },
        client: {
            type: Object,
            required: true,
        },
        portfolio: {
            type: Object,
            required: false,
            default () { return {} }
        },
    },

    data(){
        return {
            show_modal : false,
        }
    },

    methods: {
        open_operation_window(params){
            this.show_modal = false;
            this.$router.push({ path: '/new-operation/' + this.client.id + '/' + this.portfolio._id , query: params})
        }
    },

    watch: {
        open(val){
            if (val) this.show_modal = true;
        },
        show_modal(val){
            if (!val) this.$emit('closed');
        }
    }
}
</script>
